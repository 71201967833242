<template>
    <div class="container mx-auto bg-gray-300 rounded-xl shadow border p-8 m-10">
      <div class="p-4 text-blue-500">User Login</div>
      <div class="mt-7">User Name</div>
      <div><input type="text" maxlength="30" id="user_name" /></div>
      <div class="mt-7">Password</div>
      <div><input type="password" maxlength="30" id="pass_word" /></div>
      <div class="p-4">
        <button id="ok" class="inline-flex items-center justify-center rounded-md p-2 w-20 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">OK</button>
        &nbsp;
        <button id="cancel" class="inline-flex items-center justify-center rounded-md p-2 w-20 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">Cancel</button>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SignIn',
  props: {
    msg: String
  },
  methods: {
    async getPeople() {
      const people = await axios.get("https://get-people.raywmtse.workers.dev/")
      console.log(people)
    }
  },
  mounted() {
    this.getPeople()
  }

}
</script>

<style scoped>

</style>